/* eslint-disable */
export const getBackgroundCode = (angleDeg, stripes, ratio = true) => {
    let angle = angleDeg;
    if ( angle > 180 ) {
        angle -= 180;
    }

    let stripes_size_sum = 0;
    for ( let i = 0; i < stripes.length; i++ ) {
        stripes_size_sum += stripes[i].size;
    }

    //////////////////// GET BACKGROUND SIZE ///////////////////////////////
    let bg_size_x;
    let bg_size_y;
    bg_size_x = stripes_size_sum * 2;

    if ( angle > 0 && angle <= 45 ) {
        bg_size_x /= 2;
    }
    else if ( angle > 45 && angle < 90) {
        bg_size_x *= Math.tan(angle * Math.PI / 180) / 2;
    }
    else if ( angle > 90 && angle <= 135 ) {
        bg_size_x *= Math.tan((180 - angle) * Math.PI / 180) / 2;
    }
    else if ( angle > 135 && angle < 180 ) {
        bg_size_x /= 2;
    }

    if ( ratio && !(angle === 0 || angle === 90 || angle === 180) ) {
        bg_size_x /= Math.sin(angle * Math.PI / 180);
    }


    bg_size_y = bg_size_x;
    if ( angle > 90 && angle < 180 ) {
        bg_size_y = bg_size_x * Math.tan((180 - angle) * Math.PI / 180);
    }
    else if ( angle !== 90 && angle !== 0 && angle !== 180 ) {
        bg_size_y = bg_size_x * Math.tan(angle * Math.PI / 180);
    }


    if ( bg_size_y > bg_size_x ) {
        const temp  = bg_size_x;
        bg_size_x = bg_size_x * bg_size_x / bg_size_y;
        bg_size_x = bg_size_x.toFixed(2);
        bg_size_y = temp;
    }

    bg_size_x = parseFloat(bg_size_x).toFixed(2);
    bg_size_y = parseFloat(bg_size_y).toFixed(2);


    //////////////////// GET PERCENT CODE ///////////////////////////////

    const percents = [];
    for ( let i = 0; i < stripes.length; i++ ) {
        percents[i] = (stripes[i].size * 100 / stripes_size_sum) / 2;
    }

    let percents_code = '';
    for ( let i = 0; i < stripes.length * 2; i++ ) {
        let percents_sum = 0;
        for ( let j = 0; j <= i; j++ ) {
            let k = j;
            if ( j >= stripes.length ) {
                k = j - stripes.length;
            }
            percents_sum += percents[k];
        }
        percents_sum = parseFloat(percents_sum).toFixed(2).replace(/\.0+$/,'');

        let j = i;
        if ( j >= stripes.length ) {
            j = j - stripes.length;
        }

        let k = j + 1;
        if ( k >= stripes.length ) {
            k = k - stripes.length;
        }



        if ( i === stripes.length * 2 - 1 ) {
            percents_code += stripes[j].color+' '+percents_sum+'%';
        }
        else {
            percents_code += stripes[j].color+' '+percents_sum+'%, '+stripes[k].color+' '+percents_sum+'%, ';
        }
    }

    return'background-image: linear-gradient('+angle+'deg, '+percents_code+') !important;\n'+'background-size: '+bg_size_x+'px '+bg_size_y+'px;';
};

export default { getBackgroundCode };
