// eslint-disable-next-line
export const commonValues = (obj1, obj2) =>
Object.keys(obj1).reduce(
  (result, key) =>
  // eslint-disable-next-line
            obj1[key] && obj1[key] === (obj2[key] ?? null)
                ? { ...result, [key]: obj1[key] }
                : typeof obj1[key] === 'object' && obj1[key] !== null
                ? { ...result, [key]: commonValues(obj1[key], obj2[key] ?? {}) }
                : result,
        {}
    );

export const clearEmpties = (o) => {
    const result = { ...o };
    Object.keys(result).forEach((k) => {
        if (typeof o[k] === 'object' && Object.keys(o[k]).length === 0) {
            delete result[k]; // The object had no properties, so delete that property
        }
        if (typeof o[k] === 'object' && Object.keys(o[k]).length) {
            result[k] = clearEmpties(o[k]);
        }
    });
    return result;
};
export const randomIntFromInterval = (min, max) => Math.floor(Math.random() * (max - min + 1) + min);

// transforming object foo = { a: 1, b: 2} to [{keyKey: a, valueKey: 1}, {keyKey: b, valueKey: 2}]
export const mapObjectToArray = (obj, ObjectKeyAttribute, ObjectValueAttribute, isNumberKeyAttribute = false) => {
    const result = [];
    const objectValues = Object.values(obj);
    Object.keys(obj).forEach((keyValue, index) => {
        const newArrayItem = {};
        newArrayItem[ObjectKeyAttribute] = isNumberKeyAttribute ? Number(keyValue) : keyValue;
        newArrayItem[ObjectValueAttribute] = objectValues[index];
        result.push(newArrayItem)
    })
    return result;
}