import { read, utils } from "xlsx";

export const readData = async (file, onError, taskSheet = 'Tasks', linkSheet = 'Links') =>
    new Promise((resolve, reject) => {
        const apiUrl = window._env_.REACT_APP_PRINT_URL;

        const fileReader = new FileReader();
        // Read the selected file as binary string
        fileReader.readAsBinaryString(file);

        // Process the file data when it's loaded
        fileReader.onload = (event) => {
            const fileData = event.target.result;
            // Read the Excel workbook
            const workbook = read(fileData, { type: 'binary' });
            const result = {};
            // Links data
            result[linkSheet.toLowerCase()] = utils.sheet_to_json(workbook.Sheets[linkSheet], {
                raw: false,
            });
            // Task data
            window.gantt.importFromExcel({
                server: apiUrl,
                data: file,
                callback: (data) => {
                    if (data[0] && Object.keys(data[0]).includes('undefined')) {
                        onError();
                        reject(new Error('Empty header in file'));
                    }
                    result[taskSheet.toLowerCase()] = data;
                    resolve(result);
                },
            });
        };
    });

export default readData;
