import { isNil } from "lodash";

export const findParentObject = (parentId, list) => {
  let parentObject = null;
  list.filter((i) => i.isLevel).every((current) => {
      if (current.id === parentId) {
          parentObject = current;
          return false;
      }
      if (current.children?.length) {
          parentObject = findParentObject(parentId, current.children);
      }
      return true;
  });
  return parentObject;
};

export const findObjectInTree = (objectId, list) => {
  let foundObject = null;
  list.every((current) => {
      if (current.id === objectId) {
          foundObject = current;
          return false;
      }
      if (current.children?.length && foundObject === null) {
          foundObject = findObjectInTree(objectId, current.children);
      }
      return true;
  });
  return foundObject;
};

export const getArrayInListByCondition = (list, conditionKey, conditionValue) => (list || []).reduce((prev, current) => {
  
  if (!isNil(current[conditionKey]) && current[conditionKey] === conditionValue) {
      prev.push(current);
      if (current.children && current.children?.length > 0) {
        prev.push(...getArrayInListByCondition(current.children, conditionKey, conditionValue))
      }
  } else {
      prev.push(...getArrayInListByCondition((current.children || []), conditionKey, conditionValue))
  }
  return prev;
}, [])
/**
 * Filter a tree-like object by a condition and mutate node if needed
 * @param {*} node The node of beginning (NOT ARRAY)
 * @param {*} condition Function to apply for leaf when filtering
 * @param {*} mutationFunction Mutation of  node if specified
 * @returns A new tree with same structure but filtered, all leaves not matching condition removed
 */
export const filterTreeByCondition = (originalNode, condition, mutationFunction) => {
    const node = mutationFunction ? mutationFunction(originalNode) : originalNode;
    
    if (!node.children) {
      return condition(node) ? { ...node } : null;
    }

    const filteredChildren = (node.children || [])
    .map(child => filterTreeByCondition(child, condition, mutationFunction))
    .filter((child) => child !== null);
    
    return { ...node, children: filteredChildren };
}