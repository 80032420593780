export const searchData = (list, value) => {
  const find = (data) => {
      if (
          (data && data === value) ||
          (data?.toString &&
              value.toString &&
              data.toString().toLowerCase().includes(value.toString().toLowerCase()))
      )
          return true;
      return data === Object(data) && Object.values(data).some(find);
  };
  return (Array.isArray(list) ? list : Object.values(list)).filter(find);
};


export const toMap = (list, toKey) => {
    const keyValuePairs = 
      list.map(item => [toKey(item), item]);
    return new Map(keyValuePairs);
 }

 export const extractPropertyInTreeChildren = (list, extractKey='id', childrenKey = 'subActivities') =>
        list.reduce((old, current) => {
            let newValue = [...old];
            newValue.push(current[extractKey]);
            if (current[childrenKey]?.length) {
                newValue = [...newValue, ...extractPropertyInTreeChildren(current[childrenKey], extractKey, childrenKey)];
            }
            return newValue;
        }, []);

export default searchData