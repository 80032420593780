import { getBackgroundCode } from "./multi-stripes";

const getStripeDirection = (type) => {
    switch (type.toLowerCase()) {
        case 'horizontal':
            return 0;
        case 'vertical':
            return 90;
        case 'biai':
            return 135;
        default:
            return 45;
    }
};

const hasStripes = (task) => task?.colors?.length > 0;
// formatting data for building custom style
const formatForState = (task) => ({
    taskId: task.serverId ?? task.id,
    type: task.colors.length === 1 ? getStripeDirection(task.colors[0]?.hachure ?? 'horizontal') : 135 ,
    colors: task.colors.length > 1 ? task.colors.map((i) => ({
        color: i?.color ?? '#C0C0C000',
        size: 5
    })) : task.colors.reduce((last, current) => {
        if (current?.color) {
            last.push ({color: current.color, size: 5})

        }
        if (current?.trame) {
            last.push ({color: current.trame, size: 5})
        }
        return last;
    }, []),
    isMilestone: task.type === 'milestone'
});
// if milestone -45deg because of rotation +45deg
const buildStyles = (tasks) => tasks.map(
        (task) => `
    
    .task-id-${task.taskId} > .gantt_task_content {
        ${getBackgroundCode(task.type - (task.isMilestone ? 45 : 0), task.colors)}
    }
`
    );

export default { buildStyles, hasStripes, formatForState };
