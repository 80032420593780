import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import authService from '../services/auth.service';
import { refreshPlanningTabs, setActiveTab, getPlanningTabs, getActiveTabData } from '../redux/slices/app/tab.slice';
import { selectPlanningSelected } from '../redux/slices/app/planning.slice';
import { refreshPlanningSelected } from '../redux/thunks/planning-slice.thunk';

const useViewManagement = (shownViewIds, setShownViewIds, handleShareView) => {
    const [editViewKey, setEditViewKey] = useState(null);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const planningSelected = useSelector(selectPlanningSelected);
    const planningViews = useSelector((state) => state.tab.planningTabs);
    const activeTab = useSelector((state) => state.tab.activeTab);

    const handleChangeViewName = async (val, viewKey) => {
        const updatedView = await authService.updatePlanningTab(viewKey, { name: val });
        dispatch(refreshPlanningTabs('edit', updatedView));
        setEditViewKey(null);
    };

    const searchNextActiveTab = useCallback(
        async (viewKey) => {
            const indexInShown = shownViewIds.indexOf(viewKey);
            const shownViews = JSON.parse(JSON.stringify(shownViewIds));
            shownViews.splice(indexInShown, 1);
            setShownViewIds([...shownViews]);
            if (viewKey === activeTab?.id && shownViews.length > 0) {
                dispatch(
                    setActiveTab({
                        ...planningViews.find((i) => i.id === shownViews[indexInShown > 0 ? indexInShown - 1 : 0]),
                    })
                );
                await dispatch(getActiveTabData(planningSelected.id));
                dispatch(refreshPlanningSelected());
            }
            if (shownViews.length === 0) {
                dispatch(setActiveTab(null));
            }
        },
        [shownViewIds, planningViews, activeTab, dispatch, planningSelected, setShownViewIds]
    );

    const handleRemoveView = async (viewKey) => {
        await authService.deletePlanningTab(viewKey);
        dispatch(refreshPlanningTabs('delete', { id: viewKey }));
        searchNextActiveTab(viewKey);
        if (window.extraViews && window.extraViews.length > 0) {
            const extraView = window.extraViews.find((v) => v.viewId === viewKey);
            if (extraView) {
                extraView.viewInstance.close();
            }
        }
    };

    const handleContextMenuClick = async (action, viewObject) => {
        switch (action) {
            case 'edit-view-name':
                setEditViewKey(viewObject.id);
                break;
            case 'open-view-outside': {
                const viewInstance = window.open(
                    `${window.location.protocol}//${window.location.host}/planning/${viewObject.id}`,
                    viewObject.label,
                    'location=1,status=1,scrollbars=1'
                );
                if (window.extraViews) {
                    window.extraViews.push({ viewId: viewObject.id, viewInstance });
                } else {
                    window.extraViews = [{ viewId: viewObject.id, viewInstance }];
                }
                break;
            }
            case 'duplicate-view':
                await authService.duplicatePlanningTab(viewObject.id, `${viewObject.name}-Copie`);
                dispatch(getPlanningTabs());
                break;
            case 'hide-view': {
                const updatedTab = await authService.updatePlanningTab(viewObject.id, { display: false });
                dispatch(refreshPlanningTabs('edit', updatedTab));
                searchNextActiveTab(viewObject.id);
                break;
            }
            case 'delete-view':
                Modal.confirm({
                    icon: <ExclamationCircleOutlined />,
                    content: t('confirm_delete_view'),
                    onOk() {
                        handleRemoveView(viewObject.id);
                    },
                    onCancel() {
                        Modal.destroyAll();
                    },
                });
                break;
            case 'share-view':
                handleShareView();
                break;
            default:
                break;
        }
    };

    return {
        editViewKey,
        handleChangeViewName,
        handleContextMenuClick,
    };
};

export default useViewManagement;
