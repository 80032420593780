import { getBackgroundCode } from "./multi-stripes";

// formatting data for building custom style
const isDotted = (link) => link.category.id === -1; 
const formatForState = (link) => ({
    linkId: link.id,
    colors: [
        {color: link.category.color, size : 3},
        {color: '#FFFFFF00', size : 3},
    ]
});
const buildStyles = (links) => links.map(
        (link) => `
    
    .gantt_task_link.link-id-${link.linkId} .gantt_line_wrapper div {
        ${getBackgroundCode(45, link.colors)}
        background-color: unset !important
    }
`
    );

export default { buildStyles, isDotted, formatForState };
