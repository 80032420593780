import { PDFDocument } from 'pdf-lib';

export const downloadPDF = (buffer, fileName, blobObject) => {
    const blob =
        blobObject ||
        new Blob([buffer], {
            // Optional: specify the MIME Type of your file here
            type: 'application/pdf',
        });

    // download the blob
    const reader = new FileReader();
    reader.onload = (event) => {
        const base64 = event.target.result;
        const a = document.createElement('a');
        a.href = base64;
        a.download = `${fileName}.pdf`;
        a.click();
    };

    reader.readAsDataURL(blob);
};

export const mergePDFBuffers = async (buffers) => {
    const mergedPdf = await PDFDocument.create();
    /* eslint-disable-next-line */
    for (const element of buffers) {
        /* eslint-disable-next-line */
        const currentPDF = await PDFDocument.load(element);
        /* eslint-disable-next-line */
        const pdfCopiedPages = await mergedPdf.copyPages(currentPDF, currentPDF.getPageIndices());
        pdfCopiedPages.forEach((page) => mergedPdf.addPage(page));
    }
    const mergePdfBuffer = await mergedPdf.save();
    return Buffer.from(mergePdfBuffer);
};

export const apendPageContentToExistingPDFPage = async (
    targetPDFBuffer,
    sourcePDFBuffer,
    x,
    y,
    targetPageNumber,
    sourcePageNumber
) => {
    const mergedPdf = await PDFDocument.create();
    const targetPDF = await PDFDocument.load(targetPDFBuffer);
    // const targetPage = targetPDF.getPage(targetPageNumber);
    const [targetPage] = await mergedPdf.copyPages(targetPDF, [targetPageNumber]);
    mergedPdf.addPage(targetPage);
    const [sourcePage] = await mergedPdf.embedPdf(sourcePDFBuffer, [sourcePageNumber]);
    const lastPage = mergedPdf.getPage(mergedPdf.getPageCount() - 1);
    lastPage.drawPage(sourcePage, {
        x,
        y,
    });
    const mergePdfBuffer = await mergedPdf.save();
    return Buffer.from(mergePdfBuffer);
};
