import { useSelector, useDispatch } from 'react-redux';
import {
  selectSelectedGlobalNeed,
  selectSelectedPlanningNeed,
  selectGlobalNeedList,
  selectPlanningNeedList,
  selectNeedsLoading,
  selectNeedsError,
  setSelectedGlobalNeed,
  addNeed,
  updateNeed,
  deleteNeed,
  fetchGlobalNeeds,
  fetchPlanningNeeds,
  setSelectedPlanningNeed,
} from '../redux/slices/needs/needs.slice';

const useNeeds = (isPlanning = false) => {
  const dispatch = useDispatch();
  const selectedNeed = useSelector(isPlanning ? selectSelectedPlanningNeed : selectSelectedGlobalNeed);
  const needList = useSelector(isPlanning ? selectPlanningNeedList : selectGlobalNeedList);
  const loading = useSelector(selectNeedsLoading);
  const error = useSelector(selectNeedsError);

  const setSelectedNeed = (need) => {
    dispatch(isPlanning ? setSelectedPlanningNeed(need) : setSelectedGlobalNeed(need));
  };

  const refreshNeedList = (action, object) => {
    switch (action) {
      case 'new':
        dispatch(addNeed({ need: object, isPlanning }));
        break;
      case 'edit':
        dispatch(updateNeed({ need: object, isPlanning }));
        break;
      case 'delete':
        dispatch(deleteNeed({ need: object, isPlanning }));
        break;
      default:
    }
  };

  const fetchNeeds = (planningId) => {
    if (isPlanning && planningId) {
      dispatch(fetchPlanningNeeds(planningId));
    } else if (!isPlanning) {
      dispatch(fetchGlobalNeeds());
    }
  };

  return {
    selectedNeed,
    setSelectedNeed,
    needList,
    loading,
    error,
    refreshNeedList,
    fetchNeeds,
  };
};

export default useNeeds;