import moment from "moment";

export const setTokenInfo = (tokenData) => {
    localStorage.setItem('accessToken', tokenData.access_token);
    localStorage.setItem('refreshToken', tokenData.refresh_token);
    localStorage.setItem('expires_date', moment().add(tokenData.expires_in, 'seconds').valueOf());
    localStorage.setItem('refresh_expires_date', moment().add(tokenData.refresh_expires_in, 'seconds').valueOf());
};

export const deleteTokenInfo = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('expires_date');
    localStorage.removeItem('refresh_expires_date');
    localStorage.removeItem('prg');
    localStorage.removeItem('user');
};
