// eslint-disable-next-line no-extend-native
Object.defineProperty(Array.prototype, 'first', {
    value() {
        return this.length ? this.find((x) => x) : null;
    },
});
const objectFlatten = (list) =>
    list.reduce((agg, { children, ...node }) => {
        agg.push(node);

        if (children) {
            agg.push(...objectFlatten(children));
        }

        return agg;
    }, []);

// eslint-disable-next-line no-extend-native
Object.defineProperty(Array.prototype, 'flatten', {
    value() {
        return objectFlatten(this);
    },
});
